import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import Cookies from "js-cookie";

const initializeAnalytics = () => {
	const isProduction = process.env.REACT_APP_NODE_ENV === "production";

	if (isProduction) {
		const tagManagerArgs = {
			gtmId: "GTM-NWBD8XD",
		};

		TagManager.initialize(tagManagerArgs);
		Sentry.init({
			dsn: "https://0bcd108cdc644120a1005206de13b03e@o1134087.ingest.sentry.io/6181455",
			integrations: [new BrowserTracing()],
			environment: process.env.REACT_APP_NODE_ENV,

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 0.5,
			initialScope: {
				user: {
					email: String(localStorage.getItem("user_email")),
					username: String(Cookies.get("full_name")),
				},
			},
		});

		// --------------------DataDog implementation--------------------
		datadogRum.setUser({
			name: Cookies.get("full_name") || "Guest User",
			email: Cookies.get("user_id") || "Guest Email",
		});
		datadogRum.init({
			applicationId: "4824b35c-2b84-4e31-be1f-5892ad694422",
			clientToken: "pub5e90814c7ab041317eaf59fe718f924d",
			site: "datadoghq.com",
			service: "customer-portal",
			env: window.location.hostname.split(".")[0],
			sessionSampleRate: 100,
			sessionReplaySampleRate: 20,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: "mask-user-input",
		});

		datadogLogs.init({
			clientToken: "pub5e90814c7ab041317eaf59fe718f924d",
			site: "datadoghq.com",
			forwardErrorsToLogs: true,
			sessionSampleRate: 100,
			env: process.env.REACT_APP_NODE_ENV,
			service: "CUSTOMER_PORTAL",
		});

		datadogRum.startSessionReplayRecording();
		// --------------------DataDog implementation--------------------
	}
};

const setupUserAnalytics = (isLoggedIn: boolean) => {
	const isProduction = process.env.REACT_APP_NODE_ENV === "production";
	if (isLoggedIn && isProduction) {
		// clearing the guest user session
		datadogRum.clearUser();
		datadogRum.stopSession();
		datadogRum.stopSessionReplayRecording();

		//settng  the logged in user session
		datadogRum.setUser({
			name: Cookies.get("full_name"),
			email: Cookies.get("user_id"),
		});
		datadogRum.init({
			applicationId: "4824b35c-2b84-4e31-be1f-5892ad694422",
			clientToken: "pub5e90814c7ab041317eaf59fe718f924d",
			site: "datadoghq.com",
			service: "customer-portal",
			env: window.location.hostname.split(".")[0],
			sessionSampleRate: 100,
			sessionReplaySampleRate: 20,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: "mask-user-input",
		});

		datadogRum.startSessionReplayRecording();
	}
};

const analytics = {
	initializeAnalytics,
	setupUserAnalytics,
};

export default analytics;
