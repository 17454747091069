import React, { useEffect } from "react";
import {
  Grid,
  GridItem,
  Box,
  Image,
  Text,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { PageLoading } from "@karkhanaui/react";
import { useDispatch, useSelector } from "react-redux";
import { ConveyorBeltSvg, KarkhanaLogoSVG as KarkhanaLogo, PageLoaderURL, PriceSvg, QualitySvg, SettingsSvg } from "../img";

export const PublicLayout: React.FC<any> = ({ Component, ...rest }) => {
  const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
  const sideList = [
    { name: "Fair Pricing", img: PriceSvg },
    { name: "Quality Assurance", img: QualitySvg },
    { name: "Build Fast. Learn Fast", img: SettingsSvg },
    { name: "Massive Manufacturing Capacity", img: ConveyorBeltSvg },
  ];
  return (
    <Grid templateColumns={{ sm: "repeat(3, 1fr)" }} bg="white" minH="100vh">
      <Flex
        direction={"column"}
        bg="#001662"
        h="100%"
        color="white"
        p={10}
        display={{ sm: "none", lg: "block" }}
      >
        <Image
          src={KarkhanaLogo}
          width="14rem"
          mt="6.75rem"
          alt="Karkhana logo"
          height="auto"
        />
        <Text textStyle="primary.poster" mt="3.75rem">
          Manufacturing is easy with Karkhana.io
        </Text>
        <Box mt={20}>
          {sideList.map((el) => {
            return (
              <Box key={el.name} display="flex" alignItems="center" mb={8}>
                <Box
                  mr={5}
                  flex="0 0 auto"
                  bg="white"
                  borderRadius="50%"
                  height="3rem"
                  width="3rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image src={el.img} w="2rem" height="auto" alt="img" />
                </Box>
                <Text textStyle="primary.header"> {el.name} </Text>
              </Box>
            );
          })}
        </Box>
      </Flex>
      <GridItem colSpan={{ sm: 3, lg: 2 }} h="inherit">
        <Component {...rest} />
      </GridItem>
      {pageLoadingData?.loading && (
        <PageLoading
          pageLoading={pageLoadingData}
          pageLoaderURL={PageLoaderURL}
        />
      )}
    </Grid>
  );
};
