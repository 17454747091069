import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PublicLayout } from "./layouts/publicLayout";
import RoutesList from "./routes/routes";
import PrivateLayout from "./layouts/privateLayout";
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/auth/Login"));
const NotFound = lazy(() => import("./pages/error/NotFound"));
import { useDispatch, useSelector } from "react-redux";
import {
	frappeMethodService,
	Toastr,
	AppDispatch,
	logout,
	LOGIN_SUCCESS,
	setMessage,
	setLoading,
} from "@karkhanaui/react";
import Cookies from "js-cookie";
import { ProductFruits } from "react-product-fruits";
import analytics from "./analytics";
import axios, { AxiosError, AxiosResponse } from "axios";

const App: React.FC = () => {
	const [authState] = useSelector((state: any) => {
		return [state.auth];
	});
	let isLoggedIn = authState.isLoggedIn;

	analytics.initializeAnalytics();

	useEffect(() => {
		analytics.setupUserAnalytics(isLoggedIn);
	}, [isLoggedIn]);

	const toastMsg = useSelector((state: any) => state.message);
	const dispatch = useDispatch<AppDispatch>();

	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			dispatch(setLoading(false));
			return response;
		},
		function (error: AxiosError) {
			dispatch(setLoading(false));
			if (error.response?.status === 403) {
				if (isLoggedIn) {
					dispatch(logout());
				}
			}
			return Promise.reject(error);
		}
	);

	const updateLoggedInStatus = async () => {
		try {
			const service = await frappeMethodService(
				"frappe.auth.get_logged_user",
				{}
			);

			dispatch({
				type: LOGIN_SUCCESS,
				payload: {
					user: service.data?.message.user,
				},
			});
		} catch {
			if (isLoggedIn) {
				dispatch(logout());
			}
		}
	};

	useEffect(() => {
		updateLoggedInStatus();
	}, []);

	useEffect(() => {
		if (toastMsg?.message?.data) {
			setTimeout(() => {
				dispatch(setMessage(""));
			}, 3000);
		}
	}, [toastMsg]);

	return (
		<>
			<BrowserRouter>
				{isLoggedIn && process.env.REACT_APP_NODE_ENV == "production" && (
					<ProductFruits
						workspaceCode="e9yzzkJjueWMNXLn"
						language="en"
						user={{
							username: String(Cookies.get("user_id")),
							email: localStorage.getItem("user_email") || "",
							firstname: String(Cookies.get("full_name")),
						}}
					/>
				)}
				<Routes>
					{RoutesList.filter((route) => route.type === "public").map(
						(route, index) =>
							isLoggedIn ? (
								<Route
									key={index}
									path="/instantQuote"
									element={
										<Suspense>
											<PrivateLayout Component={Home} />
										</Suspense>
									}
								/>
							) : (
								<Route
									key={index}
									path={route.link}
									element={
										<Suspense>
											<PublicLayout Component={route.component} />
										</Suspense>
									}
								/>
							)
					)}
					{RoutesList.filter((route) => route.type === "private").map(
						(route, index) =>
							isLoggedIn ? (
								<Route
									key={index}
									exact
									path={route.link}
									element={
										<Suspense>
											<PrivateLayout Component={route.component} />
										</Suspense>
									}
								/>
							) : (
								<Route
									key={index}
									path="/login"
									element={
										<Suspense>
											<PublicLayout component={Login} />
										</Suspense>
									}
								/>
							)
					)}
					{isLoggedIn ? (
						<>
							{RoutesList.filter((route: any) => route.type === "public").map(
								(route: any, index: number) => (
									<Route
										key={index}
										path={route.link}
										element={
											<Suspense>
												<Navigate to="/instantQuote" replace={false} />
											</Suspense>
										}
									/>
								)
							)}
							<Route
								path="*"
								element={
									<Suspense>
										<PrivateLayout Component={NotFound} />
									</Suspense>
								}
							/>
							<Route
								path="/"
								element={
									<Suspense>
										<Navigate to="/instantQuote" replace={false} />
									</Suspense>
								}
							/>
						</>
					) : (
						<Route
							path="*"
							element={
								<Suspense>
									<Navigate to="/login" replace={false} />
								</Suspense>
							}
						/>
					)}
				</Routes>
			</BrowserRouter>
			{toastMsg?.message?.data && <Toastr toastMsg={toastMsg} />}
		</>
	);
};
export default App;
