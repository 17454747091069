import {
  Flex,
  Image,
  Box,
  FlexProps,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { GrHomeRounded } from "react-icons/gr";
import { BsFolder2Open, BsBell, BsFillPersonFill,BsFileText ,BsFiles,BsFileEarmarkMedical,BsCardHeading} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { BiShare, BiHelpCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import {
  logout,
  AppDispatch,
  PageLoading,
  NotificationCard,
  getUserDetails,
  frappeMethodService,
  ERROR_MSG,
  formatErrorMessage,
  SET_MESSAGE,
  BasicDialog,
} from "@karkhanaui/react";
import {BsSearch} from 'react-icons/bs'
import { motion } from 'framer-motion';
import { FiUser } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { GoChevronLeft } from 'react-icons/go';
import { collapseIcon, Kio, K_logo, k_logo } from "../img";

interface IconTextProps extends FlexProps {
  Icon: typeof GrHomeRounded;
  text: string;
  active?: boolean;
  link: string;
}

interface IconMiniProps extends FlexProps {
  Icon: typeof GrHomeRounded;
  text: string;
  active?: boolean;
  link: string;
}

const IconText = ({
  Icon,
  text,
  link,
  active = false,
  ...rest
}: IconTextProps) => {
  const width = window.innerWidth;
  const bgColor = width === 80 ? '#000' : '#fff';
  if (active) {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      {...rest}
      bg={active ? "rgba(0, 135, 238, 0.1);" : '#ffffff'}
      height="1em"
      borderLeft={active ? '4px solid #0087EE' : ''}
      // borderRadius="5px"
      borderTopRightRadius="md"
			borderBottomRightRadius="md"
      ml={active ? '0' : ''}
      as={Link}
      to={link}
      width="100%"
    >
      <Icon
        size={active ? '20px' : '20px'}
        style={{ color: active ? '#0087EE' : '' }}
      />
      <Box ml="11px">
        <Text
          textStyle="primary.secondaryText"
          fontWeight={active ? '700' : ''}
          fontSize="14px"
          color={active ? '#0087EE' : ''}
          wordBreak="break-all"
           whiteSpace= "nowrap"
        >
          {text}
        </Text>
      </Box>
    </Flex>
  );
  }
  return (
		<Link to={link}>
			<Flex
				justifyContent="start"
				alignItems="center"
				{...rest}
				borderRadius={"10px"}
			>
				<Icon size="20px" />
				<Box ml="11px">
					<Text textStyle="primary.text" fontSize="14px" wordBreak="break-all"  whiteSpace= "nowrap">
						{text}
					</Text>
				</Box>
			</Flex>
		</Link>
	);
};



const IconMini = ({ Icon, link, text,active = false, ...rest }: IconMiniProps) => {
  if(active){
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="0.3em"
      {...rest}
      bg={active ? "rgba(0, 135, 238, 0.1);" : '#ffffff'}
      borderLeft={active ? '4px solid #0087EE' : ''}
      borderRadius="5px"
      // ml={active ? '0' : ''}
      ml="0"
      as={Link}
      to={link}
      p= "0.8em 1.8em"
      width="100%"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
    >
      <Icon size="26px" style={{ color: active ? '#0087EE' : '' }} />
      <Text
					fontSize="10px"
					fontWeight={"700"}
					color="#0087EE"
					wordBreak="break-all"
          whiteSpace= "nowrap"
				>
					{text}
				</Text>
    </Flex>
  );
  }
  return (
		<Flex
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			gap="0.3em"
			{...rest}
			borderRadius={"10px"}
			ml="0"
			p="0.6em"
			as={Link}
			to={link}
      borderTopRightRadius="md"
			borderBottomRightRadius="md"
			width="100%"
		>
			<Icon size="26px"  />
			<Text fontSize="11px" wordBreak="break-all" whiteSpace= "nowrap">
				{text}
			</Text>
		</Flex>
	);
};

export const AppShell = ({
  children: Component,
  imageURL,
  pageLoaderURL,
  notifications,
  bread,
  // collapseIcon,
  navigateToDoc,
}: {
  children?: React.LazyExoticComponent<React.FC<any>>;
  imageURL: string;
  pageLoaderURL: string;
  notifications: any[];
  bread: any;
  // collapseIcon: string;
  navigateToDoc: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  let pathname = useLocation()?.pathname.split("/");
  const navigate = useNavigate();
  const [pageLoadingData, userData] = useSelector((state: any) => [
    state.pageLoading,
    state.getUser.customer,
  ]);

  const [activeTab, setActiveTab] = React.useState<String>("/" + pathname[1]);
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const [isMobileTablet] = useMediaQuery('(max-width: 768px)');

  const [searchValue, setSearchvalue] = React.useState<any>("");
  const [searchContent, setSearchContent] = React.useState<any>([]);
  const [showSearch, setShowSearch] = React.useState<any>(false);
  const [collapsed, setCollapsed] = React.useState(false);
 

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setActiveTabBackground();
  }, [pathname[1]]);

  const setActiveTabBackground = () => {
    setActiveTab("/" + pathname[1]);
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: window.location.pathname,
    });
  }, [window.location]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const menuItems = [
    {
      icon: BsFileText,
      text: "Instant Quote",
      link: "/instantQuote",
    },
    {
      icon: BsFiles,
      text: "Quotes",
      link: "/quotes",
    },
    {
      icon: BsFileEarmarkMedical,
      text: "Orders",
      link: "/orders",
    },
    {
      icon: BsFolder2Open,
      text: "Items Library",
      link: "/itemsLibrary",
    },
    {
      icon: BsCardHeading,
      text: "Contracts",
      link: "/contracts",
    },
    {
      icon: BiHelpCircle,
      text: "Help",
      link: "/help",
    },
  ];

  const handleSignout = () => {
    dispatch(logout());
  };
  

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 1, x: '-5%' }
  }; 

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1
      }
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  };
  
  const callGlobalSearch = async () => {
    let data: any = {
      search : searchValue
    };
   await frappeMethodService("customer_portal_v2.api.global_search.global_search",data).then(
      (res: any) => {
        let arr = res.data?.message.filter((el: any) =>
          ["Opportunity", "Sales order"].includes(el.doctype)
        );
        setSearchContent(arr);
        return Promise.resolve(res);
      },
      (error) => {
        const message = "Search failed. " + formatErrorMessage(error);
        dispatch({
          type: SET_MESSAGE,
          payload: {
            case: ERROR_MSG,
            data: message,
          },
        });
        return Promise.reject(message);
      }
    );
  };


  const redirectFn = ({name, type}:any) => {
    setShowSearch(false);
    if (type === "Opportunity") navigate(`/instantQuote/newQuote/${name}`);
    
    else navigate(`/orders/${name}/orderSummary`);
   
  };

  const listContent = (type : any) => {
    return (
      <Box>
        {searchContent?.filter((el: any) => el.doctype === type).length > 0 && (
          <Text fontSize="md" fontWeight="500">
            {type}
          </Text>
        )}
        <Box ml={5} mt={2}>
          {searchContent
            ?.filter((el: any) => el.doctype === type)
            ?.map((el: any, i: any) => {
              return (
                <Text
                  key={i}
                  fontSize="xs"
                  lineHeight={7}
                  onClick={() => redirectFn({name: el.name, type})}
                  cursor="pointer"
                  _hover={{
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  {el.name}
                </Text>
              );
            })}
        </Box>
      </Box>
    );
  };

  return (
    <Flex>

            {/* Sidebar */}
        <Flex
        w={collapsed ? (isMobile ? '0%' : '7%') : isMobile ? '80%' : '20%'}       
        flexShrink="0"
        flexDirection="column"
        h="calc(100vh)"
        justifyContent="space-between"
        // boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
        overflowX={isMobile ? 'hidden' : 'scroll'}
        display={collapsed && isMobile ? "none" : "block"}

      >
        <motion.div animate={collapsed ? 'closed' : 'open'} variants={variants}>
          <Flex
            flexDirection="column"
            w="100%"
            mt="10px"
            justifyContent="space-between"
          >
            {collapsed ? (
              <>
              <Flex flexDirection="column" width="100%" alignItems="center"    mb="4.8125rem"
                 ml="0.5em">
              <Flex paddingRight={"1em"}>
                <Image w="4rem" height="1.5rem" src={K_logo} m="0.8rem 0 2rem 0" onClick={() => navigate("/instantQuote")}/>             
                </Flex>

                {menuItems.map((item) => {
                  return (
                    <motion.div
                      variants={sideVariants}
                      // whileHover={{ scale: 1.1 }}
                      key={item.text}
                      style={{
                        width: "100%",
                        // alignItems:"center" 
                      }}
                     
                    >
                      <IconMini
                        key={item.text}
                        mt="1em"
												mx="auto"
                        // marginY="1rem"
                        Icon={item.icon}
                        alignItems="center"
                        active={activeTab === item.link}
                        _active={{ color: 'blue.500' }}
                        _hover={{ color: "#0087EE" }}
                        link={item.link}
                        onClick={() => {
                          setActiveTab(item.link);
                        }}
                        text={item.text}
                      />
                    </motion.div>
                  );
                })}
                </Flex>
              </>
            ) : 
            (
              <>
                <Image w="75%" src={imageURL} mb="2em" mt=".8rem" ml="1.5em"  onClick={() => navigate("/instantQuote")}/>
                {menuItems.map((item) => (
                  <motion.div
                    variants={sideVariants}
                    // whileHover={{ scale: 1.1 }}
                    key={item.text}
                    style={{
                      width: "100%",
                    }}
                  >
                  <Flex align="center" _hover={{ color: "#0087EE" }}>
                    <IconText
                      key={item.text}
                      Icon={item.icon}
                      text={item.text}
                      fontSize="13px"
                      cursor="pointer"
                      p="1.5rem 0.6rem"
                      active={activeTab === item.link}
                      link={item.link}
                      onClick={() => {
                        setActiveTab(item.link);
                      }}
                    />
                  </Flex>
                  </motion.div>
                ))}
              </>
            )
          }
          </Flex>
        </motion.div>
      </Flex>
      {/* Sidebar */}
      
      <Flex direction="column" 
      // w="80%"
      width={isMobile ? (collapsed ? '100%' : '20%') : '100%'}
      
      >
      <Box h="65px">
          <Flex
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            padding="10px"
          >
            <Flex>
              {collapsed ? (
                <AiOutlineMenu
                  size={20}
                  onClick={toggleCollapsed}
                  cursor="pointer"
                  style={{ color: '#0087EE'}}
                />
              ) : 
              (
                <GoChevronLeft
                  size={25}
                  onClick={toggleCollapsed}
                  cursor="pointer"
                  style={{ color: '#0087EE'} }
                
                />
              )}
              <Box style={{whiteSpace:"nowrap" , marginLeft:"0.5em", fontWeight:"700"}}>{bread}</Box>
         
            </Flex>
            <Flex
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap="1.5rem"
              width="100%"
            >
              <Flex direction="row" alignItems="center" gap="1rem">
                {/* <Button
                                    onClick={() => toggleColorMode()}
                                    children={colorMode === "dark" ? <BiMoon /> : <BsSun />}
                                    variant={"link"}
                                /> */}
                <BsSearch size="20px" cursor="pointer" />
                <Popover isLazy size="4xl" variant="responsive">
                  <PopoverTrigger>
                    <span>
                      <BsBell size="20px" cursor="pointer" />
                    </span>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p="0px">
                      <NotificationCard
                        notificationList={notifications}
                        navigate={navigateToDoc}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
              {!isMobile && (
                <Box>
                  <Stack direction="row" alignItems="center">
                    <Box textAlign={'right'}>
                      <Text textStyle="primary.smallTitle">
                        Hi, {userData.customer}
                      </Text>
                    </Box>
                    <Menu isLazy={true}>
                      <MenuButton transition="all 0.2s">
                        {/* <Image
                        src="https://picsum.photos/50/50"
                        alt="profile image"
                        rounded="full"
                        w="36px"
                        h="36px"
                      /> */}
                        <BsFillPersonFill
                          size={30}
                          color="#babcbe"
                          cursor="pointer"
                        />
                      </MenuButton>
                      <MenuList zIndex={91000}>
                        <MenuItem
                                                    icon={<IoPersonOutline />}
                                                    onClick={() => {
                                                        setActiveTab("");
                                                        navigate("/profile");
                                                    }}
                                                >
                                                    <Text textStyle="primary.smallTitle">My Profile</Text>
                                                </MenuItem>
                                                <MenuDivider />
                        <MenuItem icon={<BiShare />} onClick={handleSignout}>
                          <Text textStyle="primary.smallTitle">Sign Out</Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Stack>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
  
        {/* dialog  starts*/}
        <BasicDialog
        isOpen={showSearch}
        onClose={() => setShowSearch(false)}
        showCloseButton = {true}
        header="Search"
        content={
          <Box mb={10}>
            <Input
              placeholder="Search"
              fontSize="sm"
              value={searchValue}
              onChange={(e) => setSearchvalue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  callGlobalSearch();
                }
              }}
            />

            <Box maxH="50vh" overflowY="auto" mt={2}>
              {searchContent.length === 0 ? (
                <Text> Modify search</Text>
              ) : (
                <Tabs
                  mt={15}
                  isLazy
                  isFitted
                  index={activeTab}
                  onChange={(index) => setActiveTab(index)}
                  colorScheme="kio"
                  orientation="vertical"
                >
                  <TabList border="none" h="15vh">
                    {["All Results", "Opportunity", "Sales order"].map(
                      (el, i) => {
                        return (
                          <Tab
                            key={i}
                            fontSize={{ xs: "xs", lg: "md" }}
                            _focus={{ boxShadow: "none" }}
                            _selected={{
                              bg: "#CAE9F5",
                              color: "#1E3F66",
                              borderRadius: "6px",
                            }}
                            justifyContent="flex-start"
                            mb={2}
                          >
                            {el}
                          </Tab>
                        );
                      }
                    )}
                  </TabList>
                  <TabPanels>
                    <TabPanel key={0}>
                      {listContent("Opportunity")}
                      <Divider my={2} />
                      {listContent("Sales order")}
                    </TabPanel>
                    <TabPanel key={1}>{listContent("Opportunity")}</TabPanel>
                    <TabPanel key={2}>{listContent("Sales order")}</TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </Box>
          </Box>
        }
        />
        {/* dialog ends */}
        <Flex
          bg="bg"
          flexGrow="1"
          // padding="1rem"
          height="60vh"
          overflowY="scroll"
        >
          {!!Component && <Component />}
        </Flex>
      </Flex>
      {pageLoadingData?.loading && (
        <PageLoading
          pageLoading={pageLoadingData}
          pageLoaderURL={pageLoaderURL}
        />
      )}
    </Flex>
  );
};

