import { lazy } from "react";
const ContractsMain = lazy(() => import("../pages/Contracts/ContractsMain"));
const Home = lazy(() => import("../pages/Home/Home"));
const ItemsMain = lazy(() => import("../pages/ItemsLibrary/ItemsMain"));
const File = lazy(() => import("../pages/Contracts/File"));
const Contracts = lazy(() => import("../pages/Contracts/ContractsTable"));
const NDA = lazy(() => import("../pages/quote/NDA"));
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const Help = lazy(() => import("../pages/help/Help"));
const AddItems = lazy(() => import("../pages/ItemsLibrary/AddItems"));
const Profile = lazy(() => import("../pages/Settings/Profile"));
const RfqMain = lazy(
	() => import("../pages/instantQuote/NewAutoQuote/RfqMain")
);
const PartConfigure = lazy(
	() => import("../pages/instantQuote/ConfigurePart/PartConfigure")
);
const Checkout = lazy(() => import("../pages/instantQuote/Checkout/Checkout"));
const Orders = lazy(() => import("../pages/orders/Orders"));
const OrderSummary = lazy(() => import("../pages/orders/OrderSummary"));
const Quotes = lazy(() => import("../pages/quote/Quotes"));
const ItemsLibrary = lazy(() => import("../pages/quote/ItemsLibrary"));
const NotFound = lazy(() => import("../pages/error/NotFound"));
const QuotationDetails = lazy(() => import("../pages/quote/QuotationDetails"));

const RoutesList: Array<{
	label: string;
	link: string;
	type: "public" | "private";
	component: typeof ForgotPassword;
}> = [
	//access pages
	{
		label: "Login",
		link: "/login",
		type: "public",
		component: Login,
	},
	{
		label: "Register",
		link: "/register",
		type: "public",
		component: Register,
	},
	{
		label: "ForgotPassword",
		link: "/forgotPassword",
		type: "public",
		component: ForgotPassword,
	},
	{
		label: "ResetPassword",
		link: "/resetPassword/:changePasswordKey",
		type: "public",
		component: ResetPassword,
	},

	{
		label: "RFQ",
		link: "/instantQuote/newQuote/:rfq_id",
		type: "private",
		component: RfqMain,
	},

	{
		label: "Quotation Details",
		link: "/instantQuote/newLargeQuote/:rfq_id",
		type: "private",
		component: QuotationDetails,
	},

	//InstantQuote
	{
		label: "InstantQuote",
		link: "/instantQuote",
		type: "private",
		// component: InstantQuote,
		component: Home,
	},
	//RFQ

	{
		label: "Part Configure",
		link: "/instantQuote/newQuote/:rfq_id/configure/:part_Id",
		type: "private",
		component: PartConfigure,
	},
	{
		label: "Checkout",
		link: "/instantQuote/newQuote/:rfq_id/checkout",
		type: "private",
		component: Checkout,
	},
	//Quotes
	{
		label: "Quotes",
		link: "/quotes",
		type: "private",
		component: Quotes,
	},
	{
		label: "Items Library",
		link: "/itemsLibrary",
		type: "private",
		component: ItemsLibrary,
	},
	// Orders
	{
		label: "Orders",
		link: "/orders",
		type: "private",
		component: Orders,
	},
	{
		label: "Order Summary",
		link: "/orders/:order_id/orderSummary",
		type: "private",
		component: OrderSummary,
	},
	{
		label: "NDA",
		link: "/contracts",
		type: "private",
		component: NDA,
	},
	{
		label: "Profile",
		link: "/profile",
		type: "private",
		component: Profile,
	},

	//Error
	{
		label: "Error",
		link: "/notFound",
		type: "private",
		component: NotFound,
	},
	{
		label: "Help",
		link: "/help",
		type: "private",
		component: Help,
	},
	{
		label: "Home",
		link: "/home",
		type: "private",
		component: Home,
	},
	{
		label: "CreateContract",
		link: "/createcontract",
		type: "private",
		component: File,
	},
	{
		label: "Contract",
		link: "/contract",
		type: "private",
		component: ContractsMain,
	},
	// procurement Items-library
	{
		label: "ItemsLibrary",
		link: "/items",
		type: "private",
		component: ItemsMain,
	},
	{
		label: "AddItems",
		link: "/additems",
		type: "private",
		component: AddItems,
	},
];

export default RoutesList;
