import karkhanaLogo from "./karkhana-logo-blue.png";
import PageLoaderURL from "./loader.gif";
import ContractImage from "./background2.png";
import KarkhanaLogoSVG from "./karkhana-logo.svg";
import drawing from "./drawing.png";
import alternativeImg from "./k.svg";
import loaderImg from "./loader.gif";
import quoteSubmitImg from "./quoteSubmitted.jpg";

import k_logo from "./k_logo.svg";
import collapseIcon from "./Path.svg";
import K_logo from "./karkhana_logo_sidebar.png";

import Vector from "./Vector.png";
import Man from "./3323583 1.png";
import NewSuppliers from "./NewSuppliers.png";
import Orders from "./Orders.png";
import Po from "./Po.png";
import Rfqs from "./Rfqs.png";
import InstantQuote from "./InstantQuote.png";
import Trolley from "./trolley.png";
import Purchase from "./Purchase.png";
import InstantQuotation from "./InstantQuotation.png";
import PurchaseOrder from "./PurchaseOrder.png";
import Gears from "./Gears.png";

import errorImg from "./404.png";

import PriceSvg from "./price.svg";
import QualitySvg from "./quality.svg";
import SettingsSvg from "./settings.svg";
import ConveyorBeltSvg from "./conveyor-belt.svg";

import HVP from "./HighVolumeProduction.svg";
import IQ from "./InstantQuotation.svg";
import FileUpload from "./FileUpload.svg";
import FileStorage from "./FileStorage.svg";

export {
	karkhanaLogo,
	PageLoaderURL,
	ContractImage,
	KarkhanaLogoSVG,
	drawing,
	alternativeImg,
	alternativeImg as Kio,
	loaderImg,
	quoteSubmitImg,
	k_logo,
	collapseIcon,
	K_logo,
	Vector,
	Man,
	NewSuppliers,
	Orders,
	Po,
	Rfqs,
	InstantQuote,
	Trolley,
	Purchase,
	InstantQuotation,
	PurchaseOrder,
	Gears,
	errorImg,
	PriceSvg,
	QualitySvg,
	SettingsSvg,
	ConveyorBeltSvg,
	HVP,
	IQ,
	FileUpload,
	FileStorage,
};
