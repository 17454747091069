import React from "react";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@karkhanaui/react";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "@karkhanaui/react";

import { createRoot } from "react-dom/client";
const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</Provider>
);

if (process.env.REACT_APP_NODE_ENV !== "production") {
	const parcelSocket = new WebSocket("ws://localhost:1234/");
	parcelSocket.onmessage = () => {
		location.reload();
	};
}


if(module.hot) {
	module.hot.accept()
}
