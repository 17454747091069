import React, { useEffect } from "react";
import {
	AppDispatch,
	ERROR_MSG,
	formatErrorMessage,
	get_doc,
	setNotificationAsRead,
	SET_MESSAGE,
} from "@karkhanaui/react";
import { BiChevronRight } from "react-icons/bi";
import { AppShell } from "./AppShell";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import { karkhanaLogo, PageLoaderURL } from "../img";

const makeBreadCrumb = (location: Location) => {
	const _path = location.pathname;
	const _pathLength = _path.split("/").length;

	if (_pathLength === 2) {
		return (
			<Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink href={_path}>
						{startCase(_path.split("/")[1])}
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>
		);
	}

	if (_pathLength > 2) {
		if (_path.startsWith("/orders")) {
			let maps = _path.split("/");
			let breadcrumbData = [
				{
					link: "/orders",
					title: "Orders",
				},

				{
					link: `/orders/${maps[2]}/orderSummary`,
					title: maps[2],
				},
			];

			return (
				<Breadcrumb
					spacing="8px"
					separator={<BiChevronRight color="gray.500" />}
				>
					{breadcrumbData.map((each) => (
						<BreadcrumbItem key={each.title}>
							<BreadcrumbLink href={each.link}>{each.title}</BreadcrumbLink>
						</BreadcrumbItem>
					))}
				</Breadcrumb>
			);
		} else if (_path.startsWith("/instantQuote")) {
			let maps = _path.split("/");
			let breadcrumbData = [
				{
					link: "/quotes",
					title: "Quotes",
				},

				{
					link: `/instantQuote/newQuote/${maps[3]}`,
					title: maps[3],
				},
			];
			return (
				<Breadcrumb
					spacing="8px"
					separator={<BiChevronRight color="gray.500" />}
				>
					{breadcrumbData.map((each) => (
						<BreadcrumbItem key={each.title}>
							<BreadcrumbLink href={each.link} letterSpacing="1px">
								{each.title}
							</BreadcrumbLink>
						</BreadcrumbItem>
					))}
				</Breadcrumb>
			);
		}
	}
};

const PrivateLayout = ({
	Component,
}: {
	Component: React.LazyExoticComponent<React.FC<any>>;
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [notification, setNotification] = React.useState([]);
	const [bread, setBread] = React.useState();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		setBread(makeBreadCrumb(location));
	}, [location]);

	useEffect(() => {
		fetchNotifications();
	}, []);

	const fetchNotifications = async () => {
		await get_doc({
			doc: "Notification Log",
			fields: ["*"],
		})
			.then((s) => {
				setNotification(
					s.data?.data.sort((a: any, b: any) => {
						return new Date(b.creation) - new Date(a.creation);
					})
				);
			})
			.catch((error) => {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
	};

	const navigateToDoc = async (link: string, notificationId: string) => {
		navigate("/");
		await setNotificationAsRead(notificationId)
			.then((res) => fetchNotifications())
			.catch((error) => {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: formatErrorMessage(error),
					},
				});
			});
		navigate(link);
	};

	return (
		<AppShell
			children={Component}
			imageURL={karkhanaLogo}
			pageLoaderURL={PageLoaderURL}
			notifications={notification}
			bread={bread}
			// collapseIcon={collapseIcon}
			navigateToDoc={navigateToDoc}
		/>
	);
};

export default PrivateLayout;
